import { createApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { createCustomElement } from '@angular/elements';
import { WaterResourceInventoryAreaPopupComponent } from './app/shared/components/maps/water-resource-inventory-area-popup/water-resource-inventory-area-popup.component';
import { ReachPopupComponent } from './app/shared/components/maps/reach-popup/reach-popup.component';
import { AppComponent } from './app/app.component';
import { CountyPopupComponent } from './app/shared/components/maps/county-popup/county-popup.component';

(async () => {
  const app = createApplication(appConfig);
  (await app).bootstrap(AppComponent);
  //  (await app).bootstrap(MsalRedirectComponent);

  const wriaPopupComponent = createCustomElement(WaterResourceInventoryAreaPopupComponent, {
    injector: (await app).injector
  });
  customElements.define('water-resource-inventory-area-popup-custom-element', wriaPopupComponent);

  const reachPopupComponent = createCustomElement(ReachPopupComponent, {
    injector: (await app).injector
  });
  customElements.define('reach-popup-custom-element', reachPopupComponent);

  const countyPopupComponent = createCustomElement(CountyPopupComponent, {
    injector: (await app).injector
  });
  customElements.define('county-popup-custom-element', countyPopupComponent);
})();
