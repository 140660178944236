import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input() icon: typeof IconInterface;
  @Input() enableStroke: boolean = false;
  @Input() enableFill: boolean = true;
}

export let IconInterface:
  | 'Administrative'
  | 'AngleDown'
  | 'ArrowLeft'
  | 'Ban'
  | 'Barrier'
  | 'Book'
  | 'Boundary'
  | 'Calendar'
  | 'CaretDown'
  | 'CaretUp'
  | 'Configure'
  | 'Data'
  | 'Delete'
  | 'Download'
  | 'ExternalLink'
  | 'Filter'
  | 'Fish'
  | 'Hydrology'
  | 'Info'
  | 'LandCover'
  | 'LineChart'
  | 'Logo'
  | 'Manage'
  | 'Map'
  | 'MapLegend'
  | 'MapOpen'
  | 'Network'
  | 'Question'
  | 'Reach'
  | 'Review'
  | 'ReviewApprove'
  | 'ReviewReturn'
  | 'Ruler'
  | 'Salmon'
  | 'Search'
  | 'Statistics'
  | 'StepComplete'
  | 'StepIncomplete'
  | 'Transactions'
  | 'Thermometer'
  | 'Tools'
  | 'Tree'
  | 'User'
  | 'Users'
  | 'WaterQuality';
