import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { Observable, Subscription } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WaterResourceInventoryAreaService } from 'src/app/shared/generated/api/water-resource-inventory-area.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AlertDisplayComponent } from 'src/app/shared/components/alert-display/alert-display.component';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { NgIf } from '@angular/common';
import { routeParams } from 'src/app/app.routes';
import { ReachExplorerComponent, ReachExplorerEntityDto } from 'src/app/shared/components/reach-explorer/reach-explorer.component';
import { UserFilterObjectTypeEnum } from 'src/app/shared/generated/enum/user-filter-object-type-enum';

@Component({
  selector: 'app-water-resource-inventory-area-detail',
  templateUrl: './water-resource-inventory-area-detail.component.html',
  styleUrls: ['./water-resource-inventory-area-detail.component.scss'],
  standalone: true,
  imports: [
    ReachExplorerComponent,
    NgIf,
    LoadingDirective,
    AlertDisplayComponent,
  ],
})
export class WaterResourceInventoryAreaDetailComponent implements OnInit, OnDestroy {
  public currentUser: UserDto;
  public currentUser$: Observable<UserDto>;
  public reachExplorerEntities: ReachExplorerEntityDto[];

  public currentReachExplorerEntity: ReachExplorerEntityDto;
  public navigationSubscription: Subscription = Subscription.EMPTY;
  public userFilterObjectType: UserFilterObjectTypeEnum = UserFilterObjectTypeEnum.Watershed;

  constructor(
    private authenticationService: AuthenticationService,
    private waterResourceInventoryAreaService: WaterResourceInventoryAreaService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;
      // async pipe for waterResourceInventoryAreas
      this.waterResourceInventoryAreaService.wriasGet().subscribe(
        waterResourceInventoryAreas => {
          this.reachExplorerEntities = waterResourceInventoryAreas.map(x => {
            return <ReachExplorerEntityDto>{
              EntityID: x.WaterResourceInventoryAreaID,
              EntityName: x.WaterResourceInventoryAreaName,
              TotalAcres: x.TotalAcres,
              RiparianAcres: x.RiparianAcres,
              StreamMiles: x.StreamMiles,
              EntityIDAndName: x.WaterResourceInventoryAreaIDAndName
            };
          });

          const currentWaterResourceInventoryAreaID = parseInt(this.route.snapshot.paramMap.get(routeParams.reachExplorerEntityID))
          this.navigationSubscription = this.getNavigationSubscription();
          this.currentReachExplorerEntity = this.reachExplorerEntities.find(x => x.EntityID == currentWaterResourceInventoryAreaID);
        });
    });
  }

  private getNavigationSubscription(): Subscription {
    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null as any),
        switchMap((e) => {
          if (this.route.firstChild) {
            return this.route.firstChild.paramMap;
          }
          return this.route.paramMap;
        })
      )
      .subscribe((paramMap) => {
        // do something on each navigation event
        const newWaterResourceInventoryAreaID = parseInt(paramMap.get(routeParams.reachExplorerEntityID));
        if (this.reachExplorerEntities) {
          this.currentReachExplorerEntity = this.reachExplorerEntities.find((x) => x.EntityID == newWaterResourceInventoryAreaID);
        }
      });
  }
}
