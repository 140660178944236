//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Permission]

export enum PermissionEnum {
  WRIARights = 1,
  CustomRichTextRights = 2,
  FieldDefinitionRights = 3,
  FileResourceRights = 4,
  UserRights = 5
}
