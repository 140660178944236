export const environment = {
  production: false,
  staging: true,
  dev: false,
  mainAppApiUrl: 'https://internalapi-riparis.esa-qa.sitkatech.com',
  geoserverMapServiceUrl: 'https://geoserver-riparis.esa-qa.sitkatech.com/geoserver/Riparis',
  platformLongName: 'Riparis',
  defaultBoundingBoxLeft: -124.763068,
  defaultBoundingBoxRight: -116.915989,
  defaultBoundingBoxTop: 49.002494,
  defaultBoundingBoxBottom: 45.543541,
  contactEmail: 'jburns@esassoc.com',
  esriApiKey: 'AAPK56141bb7a4a64724b944e1d2ece884e7kgV8QEXg8SiiFBRGljXYT6OkhfW9LK-Or9KAU10xd1EaYQ6q_qwy_WzHYCt4miYC'
};