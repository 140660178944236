import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RouterModule } from '@angular/router';
import { CountyService } from 'src/app/shared/generated/api/county.service';
import { CountyDto } from 'src/app/shared/generated/model/models';
import { LoadingDirective } from 'src/app/shared/directives/loading.directive';


@Component({
  selector: 'county-popup',
  standalone: true,
  imports: [CommonModule, RouterModule, LoadingDirective],
  templateUrl: './county-popup.component.html',
  styleUrls: ['./county-popup.component.scss']
})
export class CountyPopupComponent implements OnInit, OnDestroy {
  @Input('county-id') countyID: number;
  public isLoading: boolean = true;

  public data$: Observable<any>;
  public county: CountyDto;

  constructor(
    private countyService: CountyService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

    this.data$ = forkJoin([
      this.countyService.countiesCountyIDGet(this.countyID)
    ]).pipe(
      tap(x => {
        this.county = x[0];
        this.isLoading = false;
      })
    )
  }

}
