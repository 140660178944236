<content-section sectionTitle="Watershed Explorer" icon="MapOpen">

    <riparis-map (onMapLoad)="handleMapReady($event)" mapHeight="1000px" selectedTileLayer="Hillshade"
        [showLegend]="false">
        <div class="choropleth" [id]="choroplethControlID">
            <h4>Select an Overlay:</h4>
            <select class="form-control" name="overlayScheme" [(ngModel)]="selectedOverlayScheme"
                (change)="updateOverlayLayer()">
                <option value="wria" selected>No Metric, Map Only</option>
                <option value="percent_vegetation_cover">Percent of Vegetation Cover</option>
            </select>
            <div class="legend" *ngIf="selectedOverlayScheme === 'percent_vegetation_cover'">
                <i style="background:#f1f8f4; opacity: .75"></i> No Data<br>
                <i style="background:#FFFFCC; opacity: .75"></i> 0%-78%<br>
                <i style="background:#C2E699; opacity: .75"></i> 79%-86%<br>
                <i style="background:#78C679; opacity: .75"></i> 87%–90%<br>
                <i style="background:#31A354; opacity: .75"></i> 91%–92%<br>
                <i style="background:#006837; opacity: .75"></i> 93%-100%<br>
            </div>
        </div>
    </riparis-map>
</content-section>

<section class="alert-container grid-12">
    <app-alert-display></app-alert-display>
</section>