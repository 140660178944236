export class NetworkStatisticsDto {
    ReachCount?: number;
    NetworkDepth?: number;
    TotalAcres?: number;
    EOWAcres?: number;
    RMZAcres?: number;
    FloodAcres?: number;
    EOWVegetatedAcres?: number;
    EOWTreeCoverAcres?: number;
    EOWShrubAcres?: number;
    EOWHerbaceousAcres?: number;
    EOWWaterAcres?: number;
    EOWNoVegetationDataAcres?: number;
    EOWNonVegetatedAcres?: number;
    RMZVegetatedAcres?: number;
    RMZShrubAcres?: number;
    RMZTreeCoverAcres?: number;
    RMZHerbaceousAcres?: number;
    RMZWaterAcres?: number;
    RMZNoVegetationDataAcres?: number;
    RMZNonVegetatedAcres?: number;
    FloodVegetatedAcres?: number;
    FloodTreeCoverAcres?: number;
    FloodShrubAcres?: number;
    FloodHerbaceousAcres?: number;
    FloodWaterAcres?: number;
    FloodNoVegetationDataAcres?: number;
    FloodNonVegetatedAcres?: number;
    MilesOfStream?: number;
    MilesOfTemperatureImpairedStream?: number;
    MilesOfSalmonBearingStream?: number;
    FishPassageBarriersCount?: number;
    ImpassableFishPassageBarriersCount?: number;
    SalmonidsSpeciesPresent: Map<string, number>;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
