<div class="alert alert-warning unassigned" *withFlag="{ currentUser: currentUser$ | async, flag: unassignedFlagEnum }">
    <div class="alert-content">
        <i class="fa fa-warning"></i> You are logged in to the Riparian Data
        Engine but have not yet been assigned a role to access the site. Please
        contact
        <a href="mailto:{{ contactEmail }}">{{ contactEmail }}</a>
        to request access.
    </div>
</div>
<section class="hero">
    <div class="hero__info">
        <span class="hero__name">Riparian Data Engine</span>
        <h1 class="hero__title">
            <span class="line line-1">Data and tools for</span><br />
            <span class="line line-2">Riparian planning</span>
        </h1>
    </div>

    <div class="hero__photo">
        <img src="../assets/main/home/hero.jpg" alt="Photo of California farmlands" />
    </div>

    <div class="hero__actions card">
        <div class="card-body">
            <h3 class="card-title">About</h3>
            <p>
                The Riparian Analysis Platform unites WDFW riparian datasets in
                Washington State. The platform tools allow planners to evaluate
                the conditions of riparian ecosystems, and make data-informed
                planning decisions.
            </p>

            <ng-container *ngIf="currentUser$ | async as currentUser; else notSignedIn">
            </ng-container>
            <ng-template #notSignedIn>
                <div class="logged-out">
                    <div class="hero__btns">
                        <div class="hero__btns-wrapper">
                            <button (click)="login()" class="btn btn-primary">
                                Sign In
                            </button>
                        </div>

                        <div class="hero__btns-wrapper">
                            <button (click)="signUp()" class="btn btn-secondary-outline">
                                Create an Account
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</section>

<ng-container *ngIf="currentUser$ | async as currentUser">
    <content-section sectionTitle="Tools" icon="Tools" *withRole="{
            currentUser: currentUser,
            roles: [RoleEnum.SystemAdmin, RoleEnum.ReadOnly]
        }">
        <div class="tool-box-container">
            <tool-box toolBoxTitle="Watershed Explorer" icon="MapOpen" toolBoxColor="Yellow" link="/wrias"
                toolBoxContent="Browse watersheds to discover patterns in riparian reach data."></tool-box>
            <tool-box toolBoxTitle="County Explorer" icon="MapOpen" toolBoxColor="Green" link="/counties"
                toolBoxContent="Browse Counties to discover patterns in riparian reach data."></tool-box>
            <!-- <tool-box toolBoxTitle="City Explorer" icon="MapOpen" toolBoxColor="Blue" link="/urban-growth-area"
                toolBoxContent="Browse Cities and Urban Growth Areas to discover patterns in riparian reach data."></tool-box> -->
        </div>
    </content-section>
</ng-container>

<content-section sectionTitle="Data" icon="Data">
    <p>
        The Platform brings together a wide collection of datasets that<br />
        allow users to find and evaluate riparian conditions.
    </p>
    <div class="data-card-wrapper">
        <data-card cardTitle="Boundary Data" icon="Boundary">
            <ul>
                <li>Counties</li>
                <li>WRIAs</li>
                <li>Watersheds (HUC 10, HUC 12)</li>
                <li>Cities/UrbanGrowth Areas</li>
                <li>Parcels</li>
            </ul>
        </data-card>
        <data-card cardTitle="Fish Data" icon="Fish">
            <ul>
                <li>SWIFD (Statewide Integrated Fish Distribution)</li>
                <li>ESA critical habitat</li>
            </ul>
        </data-card>
        <data-card cardTitle="WDFW Data" icon="Tree">
            <ul>
                <li>RMZs and Extent of Observed Water</li>
                <li>High Resolution Land Cover</li>
                <li>High Resolution Change Detection</li>
                <li>Riparian Canopy Pattern Metric</li>
                <li>Fish Passage Barriers</li>
            </ul>
        </data-card>
        <data-card cardTitle="Other Data" icon="Thermometer">
            <ul>
                <li>Protected Areas Database (USGS)</li>
                <li>Temperature impaired reaches (Dept. of Ecology)</li>
                <li>Streams & waterbodies (NHD)</li>
            </ul>
        </data-card>
    </div>
</content-section>

<!-- <content-section sectionTitle="Stories" icon="Book">
        <story-map></story-map>
    </content-section> -->

<section class="alert-container grid-12">
    <app-alert-display></app-alert-display>
</section>