<footer class="site-footer grid-12">
    <div class="credits copy copy-3">
        <custom-rich-text [customRichTextTypeID]="footerRichTextID"></custom-rich-text>
    </div>
    <div class="system-info" *ngIf="systemInfo$ | async as systemInfo">
        <span class="version" [title]="systemInfo.FullInformationalVersion"
            >Version: {{ systemInfo.Version }} - {{ systemInfo.Environment }}</span
        >
        <span class="version">Built on: {{ systemInfo.CompilationDateTime | date: 'short' }}</span>
    </div>
</footer>
