import { Component, Input } from '@angular/core';
import { default as vegaEmbed, VisualizationSpec } from 'vega-embed';
import { LoadingDirective } from '../../../directives/loading.directive';
import { VegaChartField } from '../VegaChartField';
import { ChartData } from '../ChartData';
import { VegaColorScale } from '../VegaColorScale';

@Component({
  selector: 'zone-area-radial-chart',
  standalone: true,
  imports: [LoadingDirective],
  templateUrl: './zone-area-radial-chart.component.html',
  styleUrl: './zone-area-radial-chart.component.scss'
})
export class ZoneAreaRadialChartComponent {
  @Input() xAxis: VegaChartField;
  @Input() yAxis: VegaChartField;
  @Input() tooltips: VegaChartField[];
  @Input() chartData: ChartData[];
  @Input() chartHeight: number = 300;
  @Input() colorScale: VegaColorScale;
  @Input() isLoading: boolean = false;

  public chartID: string = 'zoneAreaRadialChart';

  ngOnChanges() {
    const vegaSpec = {
      $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
      width: 'container',
      height: this.chartHeight,
      autosize: {
        type: 'fit-x',
        resize: true
      },
      config: {
        legend: {
          orient: 'right',
          labelFontSize: 16,
          labelLimit: 0
        }
      }, data: {
        name: 'table',
        values: this.chartData,
      },
      transform: [
        {
          joinaggregate: [{ op: 'sum', field: 'YValue', as: 'Total' }],
          groupby: ['XValue']
        },
        {
          joinaggregate: [{ op: 'sum', field: 'HoverValue', as: 'TotalPercentage' }],
          groupby: ['XValue']
        }
      ],
      encoding: {
        theta: { field: 'YValue', type: 'quantitative', stack: true },
        radius: { field: 'YValue', scale: { type: 'sqrt', zero: true, rangeMin: 100 } },
        color: {
          sort: 'ascending',
          field: 'XValue',
          title: '',
          type: 'nominal',
          scale: {
            domain: ['Riparian Management Zone', 'Extent of Observed Water', 'Floodplain'],
            range: ['#CDF57A', '#9EBBD7', '#9ED7C2']
          }
        },
        tooltip: [
          { Field: 'XValue', Title: 'Zone', Type: 'ordinal' },
          { Field: 'YValue', Title: 'Acres', Type: 'quantitative', Format: ',.1f' },
          { Field: 'HoverValue', Title: '% Acres', Type: 'quantitative', Format: '.1%' }
        ].map(x => { return { field: x.Field, title: x.Title, type: x.Type, format: x.Format } })
      },
      layer: [
        {
          mark: { type: 'arc', innerRadius: 40, stroke: '#fff' }
        },
        {
          mark: { type: 'text', radiusOffset: 30, fontSize: 14 }
        }
      ]
    } as VisualizationSpec;

    vegaEmbed(`#${this.chartID}`, vegaSpec, { renderer: 'svg' }).then(res => { });

  }
}

