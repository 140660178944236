//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Flag]

export enum FlagEnum {
  CanImpersonateUsers = 1,
  HasManagerDashboard = 2,
  HasAdminDashboard = 3,
  ShowUnassignedMessage = 4
}
