//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[CustomRichTextType]

export enum CustomRichTextTypeEnum {
  PlatformOverview = 1,
  Disclaimer = 2,
  Homepage = 3,
  Help = 4,
  LabelsAndDefinitionsList = 5,
  Training = 7,
  Footer = 8,
  EditUsers = 9,
  AboutPage = 10,
  Acknowledgements = 11,
  TemperatureImpairedWaterbodies = 12,
  FishPassageBarriers = 13,
  SWIFDSalmonDistribution = 14,
  PercentTreeCover = 15,
  PercentVegetated = 16,
  PermanentIdentifier = 17,
  NaturalChangeAcres = 18,
  AnthropogenicChangeAcres = 19,
  StreamName = 20,
  Acres = 21,
  Zones = 22,
  FishPassageBarriersCount = 23,
  ImpassableFishPassageBarriersCount = 24,
  NetworkDepth = 25,
  LandUse = 26,
  Ownership = 27,
  BankAspects = 28,
  AverageCanopyHeight = 29,
  AverageElevation = 30,
  AverageSlope = 31,
  AverageSolar = 32,
  CanopyPatternMetric = 33,
  Agriculture = 34,
  AgricultureNotClassified = 35,
  AgricultureChapter8334 = 36,
  CommericalIndustrial = 37,
  ForestryForest = 38,
  ForestryTimberland = 39,
  OpenSpace = 40,
  Residential = 41,
  ResourceProtectionAndExtraction = 42,
  Infrastructure = 43,
  UndevelopedLandAndWaterAreas = 44,
  UndevelopedLands = 45,
  FilteredRiparianAcres = 46,
  FilteredStreamMiles = 47
}
