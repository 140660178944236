import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { Observable, Subscription } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AlertDisplayComponent } from 'src/app/shared/components/alert-display/alert-display.component';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { NgIf } from '@angular/common';
import { routeParams } from 'src/app/app.routes';
import { ReachExplorerComponent, ReachExplorerEntityDto } from 'src/app/shared/components/reach-explorer/reach-explorer.component';
import { CountyService } from 'src/app/shared/generated/api/county.service';
import { UserFilterObjectTypeEnum } from 'src/app/shared/generated/enum/user-filter-object-type-enum';

@Component({
  selector: 'county-detail',
  templateUrl: './county-detail.component.html',
  styleUrls: ['./county-detail.component.scss'],
  standalone: true,
  imports: [
    ReachExplorerComponent,
    NgIf,
    LoadingDirective,
    AlertDisplayComponent,

  ],
})
export class CountyDetailComponent implements OnInit, OnDestroy {
  public currentUser: UserDto;
  public currentUser$: Observable<UserDto>;
  public reachExplorerEntities: ReachExplorerEntityDto[];

  public currentReachExplorerEntity: ReachExplorerEntityDto;
  public navigationSubscription: Subscription = Subscription.EMPTY;
  public userFilterObjectType: UserFilterObjectTypeEnum = UserFilterObjectTypeEnum.County;

  constructor(
    private authenticationService: AuthenticationService,
    private countyService: CountyService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.authenticationService.getCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.countyService.countiesGet().subscribe(
        counties => {
          this.reachExplorerEntities = counties.map(x => {
            return <ReachExplorerEntityDto>{
              EntityID: x.CountyID,
              EntityName: x.CountyName,
              TotalAcres: x.TotalAcres,
              RiparianAcres: x.RiparianAcres,
              StreamMiles: x.StreamMiles,
              EntityIDAndName: x.CountyName
            };
          });

          const currentCountyID = parseInt(this.route.snapshot.paramMap.get(routeParams.reachExplorerEntityID))
          this.navigationSubscription = this.getNavigationSubscription();
          this.currentReachExplorerEntity = this.reachExplorerEntities.find(x => x.EntityID == currentCountyID);
        });
    });
  }

  private getNavigationSubscription(): Subscription {
    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null as any),
        switchMap((e) => {
          if (this.route.firstChild) {
            return this.route.firstChild.paramMap;
          }
          return this.route.paramMap;
        })
      )
      .subscribe((paramMap) => {
        // do something on each navigation event
        const newCountyID = parseInt(paramMap.get(routeParams.reachExplorerEntityID));
        if (this.reachExplorerEntities) {
          this.currentReachExplorerEntity = this.reachExplorerEntities.find((x) => x.EntityID == newCountyID);
        }
      });
  }
}
