<div
    class="water-resource-inventory-area-title"
    [ngClass]="{ small: small == true }"
    [title]="
        waterResourceInventoryAreaName +
        ' (WRIA ' +
        waterResourceInventoryAreaID +
        ')'
    "
>
    <h2>
        <span class="water-resource-inventory-area-title__name">
            {{
                nameLength
                    ? waterResourceInventoryAreaName.substring(0, nameLength)
                    : waterResourceInventoryAreaName
            }}{{
                nameLength && waterResourceInventoryAreaName.length > nameLength
                    ? "..."
                    : ""
            }}
            <span class="water-resource-inventory-area-title__id"
                >(WRIA {{ waterResourceInventoryAreaID }})</span
            >
        </span>
    </h2>
</div>
