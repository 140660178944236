/**
 * Riparis.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class ReachSearchRequestDto { 
    EntityID?: number;
    PercentageTreeCover?: Array<number>;
    PercentageVegetated?: Array<number>;
    FilterToEcology305BList?: boolean;
    FilterToEcology303DList?: boolean;
    FilterToNotTemperatureImpaired?: boolean;
    FilterToFishBarriersPassable?: boolean;
    FilterToFishBarriersNonPassable?: boolean;
    FilterToFishBarriersUnknownPassability?: boolean;
    FilterToFishBarriersNoKnownBarrier?: boolean;
    FilterToSalmonBearing?: boolean;
    FilterToNonSalmonBearing?: boolean;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface ReachSearchRequestDtoForm { 
    EntityID?: FormControl<number>;
    PercentageTreeCover?: FormControl<Array<number>>;
    PercentageVegetated?: FormControl<Array<number>>;
    FilterToEcology305BList?: FormControl<boolean>;
    FilterToEcology303DList?: FormControl<boolean>;
    FilterToNotTemperatureImpaired?: FormControl<boolean>;
    FilterToFishBarriersPassable?: FormControl<boolean>;
    FilterToFishBarriersNonPassable?: FormControl<boolean>;
    FilterToFishBarriersUnknownPassability?: FormControl<boolean>;
    FilterToFishBarriersNoKnownBarrier?: FormControl<boolean>;
    FilterToSalmonBearing?: FormControl<boolean>;
    FilterToNonSalmonBearing?: FormControl<boolean>;
}

export class ReachSearchRequestDtoFormControls { 
    public static EntityID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PercentageTreeCover = (value: FormControlState<Array<number>> | Array<number> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<number>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PercentageVegetated = (value: FormControlState<Array<number>> | Array<number> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<number>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FilterToEcology305BList = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FilterToEcology303DList = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FilterToNotTemperatureImpaired = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FilterToFishBarriersPassable = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FilterToFishBarriersNonPassable = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FilterToFishBarriersUnknownPassability = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FilterToFishBarriersNoKnownBarrier = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FilterToSalmonBearing = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FilterToNonSalmonBearing = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
