import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Observable, forkJoin } from 'rxjs';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { FlagEnum } from 'src/app/shared/generated/enum/flag-enum';
import { routeParams } from 'src/app/app.routes';
import { RoleEnum } from 'src/app/shared/generated/enum/role-enum';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/shared/generated/api/user.service';
import { ImpersonationService } from 'src/app/shared/generated/api/impersonation.service';
import { ColDef, GridApi } from 'ag-grid-community';
import { UserDto } from 'src/app/shared/generated/model/models';
import { AlertDisplayComponent } from 'src/app/shared/components/alert-display/alert-display.component';
import { PageHeaderComponent } from 'src/app/shared/components/common/page-header/page-header.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'template-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, PageHeaderComponent, AlertDisplayComponent, RouterLink, AsyncPipe]
})
export class UserDetailComponent implements OnInit, OnDestroy {
  public userAndCurrentUser$: Observable<UserDto[]>;
  public user: UserDto;
  private currentUser: UserDto;
  public isCurrentUser: boolean;
  public userIsAdmin: boolean = false;

  public columnDefs: ColDef[];
  public csvDownloadColIDsToExclude = ['0'];
  public gridApi: GridApi;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private impersonationService: ImpersonationService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
  ) { }

  ngOnInit() {
    const userIDFromRoute = parseInt(this.route.snapshot.paramMap.get(routeParams.userID));
    const userAction = isNaN(userIDFromRoute)
      ? this.authenticationService.getCurrentUser()
      : this.userService.usersUserIDGet(userIDFromRoute);

    this.userAndCurrentUser$ = forkJoin([
      userAction,
      this.authenticationService.getCurrentUser()
    ]).pipe(
      tap(x => {
        this.user = x[0];
        this.userIsAdmin = this.user.Role.RoleID == RoleEnum.SystemAdmin;
        this.currentUser = x[1];
        this.isCurrentUser = this.user.UserID == this.currentUser.UserID;
      })
    )
  }

  impersonateUser(userID: number) {
    this.impersonationService.impersonateUserIDPost(userID).subscribe(response => {
      this.currentUser = response;
      this.authenticationService.refreshUserInfo(response);
      this.cdr.detectChanges();
      this.router.navigateByUrl('/').then(x => {
        this.alertService.pushAlert(new Alert(`Successfully impersonating user: ${this.currentUser.FullName}`, AlertContext.Success));
      });
    })
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  canUpdateUser(): boolean {
    return this.authenticationService.hasPermission(this.currentUser, PermissionEnum.UserRights, RightsEnum.Update);
  }

  canImpersonate(): boolean {
    return this.authenticationService.hasFlag(this.currentUser, FlagEnum.CanImpersonateUsers) && !environment.production;
  }
}
