import { Component, Input } from '@angular/core';
import { IconComponent, IconInterface } from '../icon/icon.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'tool-box',
  standalone: true,
  imports: [IconComponent, RouterLink],
  templateUrl: './tool-box.component.html',
  styleUrl: './tool-box.component.scss'
})
export class ToolBoxComponent {
  @Input() toolBoxTitle: string;
  @Input() icon: typeof IconInterface;
  @Input() toolBoxContent: string;
  @Input() toolBoxColor: string = '#42876226';
  @Input() buttonTitle: string = 'Explore';
  @Input() link: string;
}

export let ToolBoxColorInterface:
  | 'Green'
  | 'Yellow'