<section class="content-section">
    <div class="grid-12" [loadingSpinner]="{
            isLoading: isLoadingReach,
            loadingHeight: 100
        }">
        <div class="g-col-4 summary-statistics" *ngIf="reach">
            <div class="summary-statistics-wrapper overview">
                <div class="summary-statistics-header">
                    <h4>
                        <icon icon="Reach"></icon> Reach
                        <span class="reach-title">
                            {{ reach.StreamName }}
                        </span>
                    </h4>
                </div>
                <div class="summary-statistics-body">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="rmz-header">
                                        Riparian Management Zone
                                    </th>
                                    <th class="eow-header">
                                        Extent of Observed Water
                                    </th>
                                    <th class="flood-header">Floodplain</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Acres</td>
                                    <td>
                                        {{ reach.RMZAcres | number : "1.1-1" }}
                                    </td>
                                    <td>
                                        {{ reach.EOWAcres | number : "1.1-1" }}
                                    </td>
                                    <td>
                                        {{
                                        reach.FloodAcres | number : "1.1-1"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <field-definition [fieldDefinitionType]="
                                                'PercentTreeCover'
                                            " [inline]="false"></field-definition>
                                    </td>
                                    <td>
                                        {{
                                        reach.RMZTreeCoverAcres /
                                        reach.ReachAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        reach.EOWTreeCoverAcres /
                                        reach.ReachAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        reach.FloodTreeCoverAcres /
                                        reach.ReachAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <field-definition [fieldDefinitionType]="
                                                'PercentVegetated'
                                            " [inline]="false"></field-definition>
                                    </td>
                                    <td>
                                        {{
                                        reach.RMZVegetatedAcres /
                                        reach.ReachAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        reach.EOWVegetatedAcres /
                                        reach.ReachAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        reach.FloodVegetatedAcres /
                                        reach.ReachAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="reach-stat__wrapper">
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Ruler"></icon>
                                Miles of Stream:
                                <strong>
                                    {{ reach.StreamMiles | number : "1.1-1" }}
                                </strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Thermometer"></icon>
                                <field-definition [fieldDefinitionType]="
                                        'TemperatureImpairedWaterbodies'
                                    " [inline]="false"></field-definition>
                                <strong>
                                    {{ reach.TemperatureImpairments }}
                                </strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Barrier"></icon>
                                Fish Passage Barriers:
                                <strong>
                                    {{
                                    reach.FishPassageBarriersCount
                                    | number : "1.0-0"
                                    }}
                                    (
                                    <div class="ban">
                                        {{
                                        reach.ImpassableFishPassageBarriersCount
                                        | number : "1.0-0"
                                        }}
                                        <icon icon="Ban"></icon>
                                    </div>
                                    )
                                </strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label toggleable" [expandCollapse]="overviewSalmonidBodyLocation"
                                [startOpen]="false">
                                <icon icon="Fish"></icon>
                                Salmonids Species Present:
                                <strong>{{
                                    reachNetworkResult.SalmonidsSpeciesPresent
                                    .size
                                    }}</strong>
                                <span class="toggle" *ngIf="
                                        reachNetworkResult
                                            .SalmonidsSpeciesPresent.size > 0
                                    ">
                                    <icon icon="AngleDown"></icon>
                                </span>
                            </div>

                            <ul class="reach-stat__list" #overviewSalmonidBodyLocation>
                                <li *ngFor="
                                        let salmonSpecies of reachNetworkResult.SalmonidsSpeciesPresent
                                            | keyvalue
                                    ">
                                    {{ salmonSpecies.key }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="summary-statistics-wrapper upstream mt-4">
                <div class="summary-statistics-header">
                    <h4>
                        <icon icon="Reach"></icon> Upstream Statistics
                    </h4>
                </div>
                <ng-template #noUpstreamStatistics>
                    There are no reaches upstream.
                </ng-template>
                <div class="summary-statistics-body" *ngIf="
                        upstreamNetworkStatistics?.ReachCount > 0;
                        else noUpstreamStatistics
                    ">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="rmz-header">
                                        Riparian Management Zone
                                    </th>
                                    <th class="eow-header">
                                        Extent of Observed Water
                                    </th>
                                    <th class="flood-header">Floodplain</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Acres</td>
                                    <td>
                                        {{
                                        upstreamNetworkStatistics.RMZAcres
                                        | number : "1.1-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        upstreamNetworkStatistics.EOWAcres
                                        | number : "1.1-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        upstreamNetworkStatistics.FloodAcres
                                        | number : "1.1-1"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <field-definition [fieldDefinitionType]="
                                                'PercentTreeCover'
                                            " [inline]="false"></field-definition>
                                    </td>
                                    <td>
                                        {{
                                        upstreamNetworkStatistics.RMZTreeCoverAcres /
                                        upstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        upstreamNetworkStatistics.EOWTreeCoverAcres /
                                        upstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        upstreamNetworkStatistics.FloodTreeCoverAcres /
                                        upstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <field-definition [fieldDefinitionType]="
                                                'PercentVegetated'
                                            " [inline]="false"></field-definition>
                                    </td>
                                    <td>
                                        {{
                                        upstreamNetworkStatistics.RMZVegetatedAcres /
                                        upstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        upstreamNetworkStatistics.EOWVegetatedAcres /
                                        upstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        upstreamNetworkStatistics.FloodVegetatedAcres /
                                        upstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="reach-stat__wrapper">
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Reach"></icon>
                                Reach Count:
                                <strong>{{
                                    upstreamNetworkStatistics.ReachCount
                                    | number : "1.0-0"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Network"></icon>
                                Network Depth:
                                <strong>{{
                                    upstreamNetworkStatistics.NetworkDepth
                                    | number : "1.0-0"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Ruler"></icon>
                                Miles of Stream:
                                <strong>{{
                                    upstreamNetworkStatistics.MilesOfStream
                                    | number : "1.1-1"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Thermometer"></icon>
                                Miles of Temperature Impaired Stream:
                                <strong>{{
                                    upstreamNetworkStatistics.MilesOfTemperatureImpairedStream
                                    | number : "1.1-1"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Fish"></icon>
                                Miles of Salmon Bearing Stream:
                                <strong>{{
                                    upstreamNetworkStatistics.MilesOfSalmonBearingStream
                                    | number : "1.1-1"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Barrier"></icon>
                                Fish Passage Barriers:
                                <strong>
                                    {{
                                    upstreamNetworkStatistics.FishPassageBarriersCount
                                    | number : "1.0-0"
                                    }}
                                    (
                                    <div class="ban">
                                        {{
                                        upstreamNetworkStatistics.ImpassableFishPassageBarriersCount
                                        | number : "1.0-0"
                                        }}
                                        <icon icon="Ban"></icon>
                                    </div>
                                    )
                                </strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label toggleable" [expandCollapse]="upstreamSalmonidBodyLocation"
                                [startOpen]="false">
                                <icon icon="Fish"></icon>
                                Salmonids Species Present:
                                <strong>{{
                                    upstreamNetworkStatistics
                                    .SalmonidsSpeciesPresent.size
                                    }}</strong>
                                <span class="toggle" *ngIf="
                                        upstreamNetworkStatistics
                                            .SalmonidsSpeciesPresent.size > 0
                                    ">
                                    <icon icon="AngleDown"></icon>
                                </span>
                            </div>

                            <ul class="reach-stat__list" #upstreamSalmonidBodyLocation>
                                <li *ngFor="
                                        let salmonSpecies of upstreamNetworkStatistics.SalmonidsSpeciesPresent
                                            | keyvalue
                                    ">
                                    {{ salmonSpecies.key }}:
                                    <strong>
                                        {{
                                        salmonSpecies.value
                                        | number : "1.0-1"
                                        }}
                                        miles ({{
                                        salmonSpecies.value /
                                        upstreamNetworkStatistics.MilesOfStream
                                        | percent : "1.0-1"
                                        }})
                                    </strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="summary-statistics-wrapper downstream mt-4">
                <div class="summary-statistics-header">
                    <h4>
                        <icon icon="Reach"></icon> Downstream Statistics
                    </h4>
                </div>
                <ng-template #noDownstreamStatistics>
                    There are no reaches downstream.
                </ng-template>
                <div class="summary-statistics-body" *ngIf="
                        downstreamNetworkStatistics?.ReachCount > 0;
                        else noDownstreamStatistics
                    ">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="rmz-header">
                                        Riparian Management Zone
                                    </th>
                                    <th class="eow-header">
                                        Extent of Observed Water
                                    </th>
                                    <th class="flood-header">Floodplain</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Acres</td>
                                    <td>
                                        {{
                                        downstreamNetworkStatistics.RMZAcres
                                        | number : "1.1-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        downstreamNetworkStatistics.EOWAcres
                                        | number : "1.1-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        downstreamNetworkStatistics.FloodAcres
                                        | number : "1.1-1"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <field-definition [fieldDefinitionType]="
                                                'PercentTreeCover'
                                            " [inline]="false"></field-definition>
                                    </td>
                                    <td>
                                        {{
                                        downstreamNetworkStatistics.RMZTreeCoverAcres /
                                        downstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        downstreamNetworkStatistics.EOWTreeCoverAcres /
                                        downstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        downstreamNetworkStatistics.FloodTreeCoverAcres /
                                        downstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <field-definition [fieldDefinitionType]="
                                                'PercentVegetated'
                                            " [inline]="false"></field-definition>
                                    </td>
                                    <td>
                                        {{
                                        downstreamNetworkStatistics.RMZVegetatedAcres /
                                        downstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        downstreamNetworkStatistics.EOWVegetatedAcres /
                                        downstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                    <td>
                                        {{
                                        downstreamNetworkStatistics.FloodVegetatedAcres /
                                        downstreamNetworkStatistics.TotalAcres
                                        | percent : "1.0-1"
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="reach-stat__wrapper">
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Reach"></icon>
                                Reach Count:
                                <strong>{{
                                    downstreamNetworkStatistics.ReachCount
                                    | number : "1.0-0"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Network"></icon>
                                Network Depth:
                                <strong>{{
                                    downstreamNetworkStatistics.NetworkDepth
                                    | number : "1.0-0"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Ruler"></icon>
                                Miles of Stream:
                                <strong>{{
                                    downstreamNetworkStatistics.MilesOfStream
                                    | number : "1.1-1"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Thermometer"></icon>
                                Miles of Temperature Impaired Stream:
                                <strong>{{
                                    downstreamNetworkStatistics.MilesOfTemperatureImpairedStream
                                    | number : "1.1-1"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Fish"></icon>
                                Miles of Salmon Bearing Stream:
                                <strong>{{
                                    downstreamNetworkStatistics.MilesOfSalmonBearingStream
                                    | number : "1.1-1"
                                    }}</strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label">
                                <icon icon="Barrier"></icon>
                                Fish Passage Barriers:
                                <strong>
                                    {{
                                    downstreamNetworkStatistics.FishPassageBarriersCount
                                    | number : "1.0-0"
                                    }}
                                    (
                                    <div class="ban">
                                        {{
                                        downstreamNetworkStatistics.ImpassableFishPassageBarriersCount
                                        | number : "1.0-0"
                                        }}
                                        <icon icon="Ban"></icon>
                                    </div>
                                    )
                                </strong>
                            </div>
                        </div>
                        <div class="reach-stat">
                            <div class="reach-stat__label toggleable" [expandCollapse]="
                                    downstreamSalmonidBodyLocation
                                " [startOpen]="false">
                                <icon icon="Fish"></icon>
                                Salmonids Species Present:
                                <strong>{{
                                    downstreamNetworkStatistics
                                    .SalmonidsSpeciesPresent.size
                                    }}</strong>
                                <span class="toggle" *ngIf="
                                        downstreamNetworkStatistics
                                            .SalmonidsSpeciesPresent.size > 0
                                    ">
                                    <icon icon="AngleDown"></icon>
                                </span>
                            </div>

                            <ul class="reach-stat__list" #downstreamSalmonidBodyLocation>
                                <li *ngFor="
                                        let salmonSpecies of downstreamNetworkStatistics.SalmonidsSpeciesPresent
                                            | keyvalue
                                    ">
                                    {{ salmonSpecies.key }}:
                                    <strong>
                                        {{
                                        salmonSpecies.value
                                        | number : "1.0-1"
                                        }}
                                        miles ({{
                                        salmonSpecies.value /
                                        downstreamNetworkStatistics.MilesOfStream
                                        | percent : "1.0-1"
                                        }})
                                    </strong>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-col-8">
            <ng-container *ngIf="reach">
                <div class="page-header">
                    <h1 class="page-title page-title__watershed">
                        <span class="icon-wrapper">
                            <icon icon="MapOpen"></icon>
                        </span>
                        {{ reach.PermanentIdentifier }}
                        <span class="wria-id">
                            <a routerLink="/wrias/{{
                                    reach.WaterResourceInventoryAreaID
                                }}">
                                (WRIA
                                {{ reach.WaterResourceInventoryAreaID }})
                            </a>
                        </span>
                    </h1>
                </div>

                <btn-group-radio-input [options]="tabs" (change)="setActiveTab($event)" [default]="activeTab"
                    [showIcons]="true"></btn-group-radio-input>
            </ng-container>

            <riparis-map (onMapLoad)="handleMapReady($event)" mapHeight="800px" [hidden]="activeTab !== 'Map'">
            </riparis-map>

            <div style="height: 800px" [hidden]="activeTab !== 'LandCover'">
                <div class="mt-4">
                    <div style="display: flex">
                        <h3 class="chartTitle">Land Cover by Type</h3>
                        <i style="margin-left: auto"></i>
                        <button-group>
                            <button *ngFor="let chartOption of chartOptions" (click)="changeChartOption(chartOption)"
                                [class.active]="
                                    chartOption === currentChartOption
                                ">
                                {{ chartOption }}
                            </button>
                        </button-group>
                    </div>
                    <hr />
                    <land-cover-bar-chart *ngIf="chartData?.length > 0; else noLandCoverChartStatistics"
                        [chartData]="chartData"></land-cover-bar-chart>
                    <ng-template #noLandCoverChartStatistics>
                        There are no reaches {{ currentChartOption }}.
                    </ng-template>
                </div>
            </div>

            <div style="height: 800px" [hidden]="activeTab !== 'Reach'">
                <riparis-grid height="700px" [rowData]="reachNetworkResults" [columnDefs]="columnDefs"
                    downloadDisplayName="reaches-shown" [pagination]="true" [sizeColumnsToFitGrid]="true"
                    (gridReady)="onGridReady($event)" rowSelection="single"
                    (selectionChanged)="onSelectionChanged($event)">
                </riparis-grid>
            </div>
        </div>
    </div>
    <div class="grid-12 mt-4" *ngIf="reach"></div>

    <section class="alert-container grid-12">
        <app-alert-display></app-alert-display>
    </section>
</section>