import { Component, ComponentRef, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalOptions, ModalService, ModalSizeEnum, ModalThemeEnum } from '../../services/modal/modal.service';
import { NgTemplateOutlet } from '@angular/common';
@Component({
    selector: 'modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet]
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('dialog') dialog: ElementRef;
  @ViewChild('vc', {read: ViewContainerRef}) vc: ViewContainerRef;
  @Input() context : TemplateRef<any>;

  private _modalOptions : ModalOptions = null;
  @Input() set modalOptions(value: ModalOptions) {
    if(value?.ModalSize != null) {
      this.modalSize = value.ModalSize;
    }
    if(value?.ModalTheme != null) {
      this.modalTheme = value.ModalTheme;
    }
    if(value?.TopLayer != null) {
      this.topLayer = value.TopLayer;
    }

    if(value?.OverflowVisible != null) {
      this.overflowVisible = value.OverflowVisible;
    }

  }
  get modalOptions(): ModalOptions {
    return this._modalOptions;
  }

  public modalSize : ModalSizeEnum = ModalSizeEnum.Medium;
  public ModalSizeEnum = ModalSizeEnum;

  public modalTheme: ModalThemeEnum = ModalThemeEnum.Primary;
  public ModalThemeEnum = ModalThemeEnum;

  public topLayer: boolean = true;
  public overflowVisible: boolean = false;

  public modalComponentRef: ComponentRef<ModalComponent>;
  /**
	 * The promise that is resolved when the modal is closed and rejected when the modal is dismissed.
	 */
  result: Promise<any>;
  private _resolve: (result?: any) => void;
  private _reject: (reason?: any) => void;
  
  constructor(public viewRef: ViewContainerRef, private modalService: ModalService) { 
    this.result = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    this.result.then(null, () => {});
  }

  ngOnDestroy(): void {
    // console.log('destroying modal');
  }

  // This host listener is here to close the modal on click outside ONLY IF it's not a top-layer modal
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const event = this.dialog.nativeElement == (targetElement);
    if(event && this.topLayer === false){
      this.modalService.close(this.modalComponentRef)
    }
  }

  ngOnInit(): void {

  }

  close(result: boolean = true): void {
    (this.dialog.nativeElement as HTMLDialogElement).close();
    this._resolve(result);
  }

}
