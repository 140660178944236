import { Component, Input } from '@angular/core';
import { IconComponent, IconInterface } from '../icon/icon.component';

@Component({
  selector: 'data-card',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './data-card.component.html',
  styleUrl: './data-card.component.scss'
})
export class DataCardComponent {
  @Input() cardTitle : string;
  @Input() icon : typeof IconInterface;
}
