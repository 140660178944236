import { Component, Input } from '@angular/core';
import { IconComponent, IconInterface } from '../icon/icon.component';

@Component({
  selector: 'content-section',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './content-section.component.html',
  styleUrl: './content-section.component.scss'
})
export class ContentSectionComponent {

  @Input() sectionTitle : string;
  @Input() icon : typeof IconInterface;

}
