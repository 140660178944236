import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { Observable } from 'rxjs';
import {
  Map
} from 'leaflet';
import * as L from 'leaflet';
import { WfsService } from 'src/app/shared/services/wfs.service';
import { RiparisMapInitEvent } from 'src/app/shared/components/leaflet/riparis-map/riparis-map.component.js';
import { AlertDisplayComponent } from 'src/app/shared/components/alert-display/alert-display.component';
import { RiparisMapComponent } from 'src/app/shared/components/leaflet/riparis-map/riparis-map.component';
import { ContentSectionComponent } from 'src/app/shared/components/content-section/content-section.component';
import { CountyPopupComponent } from 'src/app/shared/components/maps/county-popup/county-popup.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'county-index',
  templateUrl: './county-index.component.html',
  styleUrls: ['./county-index.component.scss'],
  standalone: true,
  imports: [
    ContentSectionComponent,
    RiparisMapComponent,
    AlertDisplayComponent,
    CountyPopupComponent,
    FormsModule,
    NgIf
  ]
})
export class CountyIndexComponent implements OnInit, OnDestroy {
  public currentUser$: Observable<UserDto>;
  public selectedOverlayScheme: string = 'county';

  constructor(
    private wfsService: WfsService, private cdr: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  public baseOutlineWithLabelsLayer;
  public overlayLayer;
  public map: L.Map;
  public layerControl: L.layerControl;
  public mapIsReady: boolean = false;
  public choroplethControl: L.Control;
  public choroplethControlID: string = 'choroplethControl';

  handleMapReady(event: RiparisMapInitEvent): void {
    this.map = event.map;
    this.layerControl = event.layerControl;
    this.mapIsReady = true;
    const self = this;

    const choroplethControl = L.Control.extend({
      onAdd(map: Map) {
        const domElement = L.DomUtil.get(self.choroplethControlID);
        L.DomEvent.disableClickPropagation(domElement);
        return domElement;
      },
      onRemove(map: Map) { }
    });
    this.choroplethControl = new choroplethControl({
      position: 'topleft'
    }).addTo(this.map);

    this.map.on('click', (event: L.LeafletMouseEvent): void => {
      self.map.fireEvent('dataloading');
      self.wfsService.getCountyIDByCoordinate(event.latlng.lng, event.latlng.lat)
        .subscribe((reachPID: string) => {
          const startIndex = reachPID.indexOf('<Riparis:CountyID>')
          const endIndex = reachPID.indexOf('</Riparis:CountyID>')
          self.map.fireEvent('dataload');
          if (startIndex < 0 && endIndex < 0) {
            return;
          }

          const countyID = reachPID.substring(startIndex + 18, endIndex);

          // IMPORTANT: THIS ONLY WORKS BECAUSE I'VE INSTALLED @angular/elements AND CONFIGURED THIS IN THE app.module.ts bootstrapping
          L.popup({
            maxWidth: 250,
            keepInView: true
          }).setLatLng(event.latlng)
            .setContent(`<county-popup-custom-element county-id="${countyID}"></county-popup-custom-element>`)
            .openOn(self.map);
        });
    });
    this.baseOutlineWithLabelsLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + '/wms?', { layers: 'Riparis:Counties', transparent: true, format: 'image/png', tiled: true, styles: 'county_label' });
    this.map.addLayer(this.baseOutlineWithLabelsLayer);

    this.updateOverlayLayer();
    this.cdr.detectChanges();
  }


  public updateOverlayLayer(): void {
    if (this.overlayLayer) {
      this.map.removeLayer(this.overlayLayer);
    }

    this.overlayLayer = L.tileLayer.wms(environment.geoserverMapServiceUrl + '/wms?', { layers: 'Riparis:Counties', transparent: true, format: 'image/png', tiled: true, styles: this.selectedOverlayScheme });
    this.map.addLayer(this.overlayLayer);
  }
}
