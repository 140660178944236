<div class="popup-wrapper" [loadingSpinner]="{ isLoading, loadingHeight: 225 }">
    <ng-container *ngIf="data$ | async as data">
        <div class="popup-header">
            <div class="water-resource-inventory-area-title" [title]="
                permanentIdentifier +
                ' (WRIA ' +
                reach.WaterResourceInventoryAreaID +
                ')'
            ">
                <h3>
                    <span class="water-resource-inventory-area-title__name">
                        PID: {{ permanentIdentifier }}
                        <span class="water-resource-inventory-area-title__id">(WRIA {{
                            reach.WaterResourceInventoryAreaID
                            }})</span>
                    </span>
                </h3>
            </div>
        </div>

        <div class="popup-body">
            <div>
                <dl>
                    <dd *ngIf="reach.StreamName">
                        <strong>Stream Name:</strong>
                        {{ reach.StreamName }}
                    </dd>
                    <dd>
                        <strong>Total Acres:</strong>
                        {{
                        reach.ReachAcres | number : "1.0-0"
                        }}
                    </dd>
                    <dd *ngIf="reach.RiparianAcres">
                        <strong>Total Riparian Acres:</strong>
                        {{
                        reach.RiparianAcres
                        | number : "1.0-0"
                        }}
                    </dd>
                    <dd *ngIf="reach.StreamMiles">
                        <strong>Total Stream Miles:</strong>
                        {{
                        reach.StreamMiles
                        | number : "1.0-0"
                        }}
                    </dd>
                </dl>
            </div>
        </div>

        <div class="popup-footer">
            <a class="btn btn-primary btn-sm" target="_blank" [routerLink]="[
                    '/reaches/',
                    permanentIdentifier
                ]">View Reach
                <icon icon="ExternalLink"></icon>
            </a>
        </div>
    </ng-container>
</div>