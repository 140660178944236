import { Component } from '@angular/core';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { CustomRichTextComponent } from 'src/app/shared/components/custom-rich-text/custom-rich-text.component';
import { PageHeaderComponent } from 'src/app/shared/components/common/page-header/page-header.component';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
  standalone: true,
  imports: [PageHeaderComponent, CustomRichTextComponent],
})
export class AboutComponent {
  public customRichTextID: number = CustomRichTextTypeEnum.AboutPage;
}
