import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReachService } from 'src/app/shared/generated/api/reach.service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { Observable, forkJoin, tap } from 'rxjs';
import { ReachDto } from 'src/app/shared/generated/model/reach-dto';
import { LoadingDirective } from 'src/app/shared/directives/loading.directive';

@Component({
  selector: 'reach-popup',
  standalone: true,
  imports: [CommonModule, RouterModule, IconComponent, LoadingDirective],
  templateUrl: './reach-popup.component.html',
  styleUrls: ['./reach-popup.component.scss']
})
export class ReachPopupComponent implements OnInit, OnDestroy {
  @Input('permanent-identifier') permanentIdentifier: string;
  public isLoading: boolean = true;

  public data$: Observable<any>;
  public reach: ReachDto;

  constructor(
    private reachService: ReachService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.data$ = forkJoin([
      this.reachService.reachesPermanentIdentifierGet(this.permanentIdentifier)
    ]).pipe(
      tap(x => {
        this.reach = x[0];
        this.isLoading = false;
      })
    )
  }

}
