/**
 * Riparis.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class CustomRichTextSimpleDto { 
    CustomRichTextID?: number;
    CustomRichTextTypeID?: number;
    CustomRichTextTitle?: string;
    CustomRichTextContent?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface CustomRichTextSimpleDtoForm { 
    CustomRichTextID?: FormControl<number>;
    CustomRichTextTypeID?: FormControl<number>;
    CustomRichTextTitle?: FormControl<string>;
    CustomRichTextContent?: FormControl<string>;
}

export class CustomRichTextSimpleDtoFormControls { 
    public static CustomRichTextID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CustomRichTextTypeID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CustomRichTextTitle = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CustomRichTextContent = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
