<div class="page style-guide">
    <page-header pageTitle="Style Guide"> </page-header>

    <div class="page-body">
        <nav class="section-nav flex-start">
            <a href="#typography">Typography</a>
            <a href="#color">Color</a>
            <a href="#grid">Grid</a>
            <a href="#buttons">Buttons</a>
            <a href="#forms">Forms</a>
        </nav>

        <section class="style-section" id="typography">
            <div class="section-header">
                <h3 class="module-title underline">Typography</h3>
            </div>

            <div class="section-body style-grid typography-grid">
                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Page Title</h4>
                        <h1 class="page-title">Headline text goes here</h1>
                        <pre
                            class="inline"><code class="language-markup">&lt;h1 class="page-title">Headline text goes here&lt;/h1></code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Section Title</h4>
                        <h2 class="section-title">Headline text goes here</h2>
                        <pre
                            class="inline"><code class="language-markup">&lt;h2 class="section-title">Headline text goes here&lt;/h2></code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Module Title</h4>
                        <h3 class="module-title">Headline text goes here</h3>
                        <pre
                            class="inline"><code class="language-markup">&lt;h3 class="module-title">Headline text goes here&lt;/h3></code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Module Title / Underline</h4>
                        <h3 class="module-title underline">Headline text goes here</h3>
                        <pre
                            class="inline"><code class="language-markup">&lt;h3 class="module-title underline">Headline text goes here&lt;/h3></code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Copy 1</h4>
                        <div class="copy copy-1">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab
                                provident autem nemo quo ut libero, iste eveniet debitis quisquam
                                nesciunt.
                            </p>
                        </div>
                        <pre><code class="language-markup">&lt;div class="copy copy-1">
    &lt;p>Lorem ipsum dolor sit amet consectetur adipisicing elit.&lt;/p>
&lt;/div></code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Copy 2</h4>
                        <div class="copy copy-2">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab
                                provident autem nemo quo ut libero, iste eveniet debitis quisquam
                                nesciunt.
                            </p>
                        </div>
                        <pre><code class="language-markup">&lt;div class="copy copy-2">
    &lt;p>Lorem ipsum dolor sit amet consectetur adipisicing elit.&lt;/p>
&lt;/div></code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Copy 3</h4>
                        <div class="copy copy-3">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab
                                provident autem nemo quo ut libero, iste eveniet debitis quisquam
                                nesciunt.
                            </p>
                        </div>
                        <pre><code class="language-markup">&lt;div class="copy copy-3">
    &lt;p>Lorem ipsum dolor sit amet consectetur adipisicing elit.&lt;/p>
&lt;/div></code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Copy 4</h4>
                        <div class="copy copy-4">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab
                                provident autem nemo quo ut libero, iste eveniet debitis quisquam
                                nesciunt.
                            </p>
                        </div>
                        <pre><code class="language-markup">&lt;div class="copy copy-4">
    &lt;p>Lorem ipsum dolor sit amet consectetur adipisicing elit.&lt;/p>
&lt;/div></code></pre>
                    </div>
                </div>
            </div>

            <div class="guidance copy copy-3">
                <p><strong>GUIDANCE</strong></p>
                <ul>
                    <li>.page-title should be reserved for page titles only</li>
                    <li>Major sections of a page should be headed by .section-title</li>
                    <li>Sub-parts of those major sections should be head by .module-title</li>
                    <li>
                        Use .module-title.underline where in context an underline rule would be a
                        needed visual element
                    </li>
                </ul>
            </div>
        </section>

        <section class="style-section" id="color">
            <div class="section-header">
                <h3 class="module-title underline">Color</h3>
            </div>

            <div class="section-body style-grid color-grid">
                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Primary</h4>
                        <div class="swatch">
                            <span style="background: #0d5b73"></span>
                        </div>
                        <pre><code class="language-sass">$primary: #0d5b73;</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Secondary</h4>
                        <div class="swatch">
                            <span style="background: #5a9cb0"></span>
                        </div>
                        <pre><code class="language-sass">$secondary: #5a9cb0;</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Tan</h4>
                        <div class="swatch">
                            <span style="background: #f4f3ef"></span>
                        </div>
                        <pre><code class="language-sass">$tan: #f4f3ef;</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Black</h4>
                        <div class="swatch">
                            <span style="background: #000000"></span>
                        </div>
                        <pre><code class="language-sass">$black: #000000;</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">White</h4>
                        <div class="swatch">
                            <span style="background: #ffffff; border: 1px solid #ddd"></span>
                        </div>
                        <pre><code class="language-sass">$white: #ffffff;</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Red</h4>
                        <div class="swatch">
                            <span style="background: #ed6969"></span>
                        </div>
                        <pre><code class="language-sass">$red: #ed6969;</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Yellow</h4>
                        <div class="swatch">
                            <span style="background: #ffe66d"></span>
                        </div>
                        <pre><code class="language-sass">$yellow: #FFE66D;</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Green</h4>
                        <div class="swatch">
                            <span style="background: #2cb92a"></span>
                        </div>
                        <pre><code class="language-sass">$green: #2cb92a;</code></pre>
                    </div>
                </div>
            </div>
        </section>

        <section class="style-section" id="grid">
            <div class="section-header">
                <h3 class="module-title underline">Grid</h3>
            </div>

            <div class="section-body style-grid grid-grid">
                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">12 Column Grid</h4>

                        <pre
                            class="inline mb-3"><code class="language-markup">&lt;div class="grid-12">
    &lt;div class="g-col-8">&lt;/div>
    &lt;div class="g-col-4">&lt;/div>
&lt;/div></code></pre>

                        <div class="grid-12 grid-example">
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                        </div>

                        <div class="grid-12 mt-4 grid-example">
                            <div class="g-col-8">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-8">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-4">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-4">&lt;/div></code></pre>
                            </div>
                        </div>

                        <div class="grid-12 mt-4 grid-example">
                            <div class="g-col-6">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-6">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-6">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-6">&lt;/div></code></pre>
                            </div>
                        </div>

                        <div class="grid-12 mt-4 grid-example">
                            <div class="g-col-4">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-4">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-4">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-4">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-4">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-4">&lt;/div></code></pre>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">9 Column Grid</h4>

                        <pre
                            class="inline mb-3"><code class="language-markup">&lt;div class="grid-9">
    &lt;div class="g-col-6">&lt;/div>
    &lt;div class="g-col-3">&lt;/div>
&lt;/div></code></pre>

                        <div class="grid-9 grid-example">
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                        </div>

                        <div class="grid-9 mt-4 grid-example">
                            <div class="g-col-6">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-6">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-3">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-3">&lt;/div></code></pre>
                            </div>
                        </div>

                        <div class="grid-9 mt-4 grid-example">
                            <div class="g-col-3">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-3">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-3">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-3">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-3">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-3">&lt;/div></code></pre>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">8 Column Grid</h4>

                        <pre
                            class="inline mb-3"><code class="language-markup">&lt;div class="grid-8">
    &lt;div class="g-col-4">&lt;/div>
    &lt;div class="g-col-4">&lt;/div>
&lt;/div></code></pre>

                        <div class="grid-8 grid-example">
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                            <div class="g-col-1">
                                <pre
                                    class="inline"><code class="language-markup">g-col-1</code></pre>
                            </div>
                        </div>

                        <div class="grid-8 mt-4 grid-example">
                            <div class="g-col-4">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-4">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-4">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-4">&lt;/div></code></pre>
                            </div>
                        </div>

                        <div class="grid-8 mt-4 grid-example">
                            <div class="g-col-2">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-2">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-2">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-2">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-2">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-2">&lt;/div></code></pre>
                            </div>
                            <div class="g-col-2">
                                <pre
                                    class="inline"><code class="language-markup">&lt;div class="g-col-2">&lt;/div></code></pre>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="guidance inset copy copy-3">
                    <p><strong>GUIDANCE</strong></p>
                    <ul>
                        <li>Use <strong>.grid-12</strong> by default.</li>
                        <li>
                            Use <strong>.grid-8</strong> or <strong>.grid-9</strong> when needing
                            wider columns (or differently width columns) to support content in
                            context.
                        </li>
                        <li>
                            You can stack multiple grids (rows) on top of each other; you don't need
                            to attempt to fit a whole page's content in on grid.
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="style-section" id="buttons">
            <div class="section-header mb-4">
                <h3 class="module-title underline">Buttons</h3>
            </div>

            <div class="section-body style-grid btn-grid">
                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Primary</h4>
                        <button class="btn btn-primary">Button Label</button>
                        <pre><code class="language-markup">btn btn-primary</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Primary Outline</h4>
                        <button class="btn btn-primary-outline">Button Label</button>
                        <pre><code class="language-markup">btn btn-primary-outline</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Primary Large</h4>
                        <button class="btn btn-primary btn-lg">Button Label</button>
                        <pre><code class="language-markup">btn btn-primary btn-lg</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Primary Medium</h4>
                        <button class="btn btn-primary btn-md">Button Label</button>
                        <pre><code class="language-markup">btn btn-primary btn-md</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Primary Small</h4>
                        <button class="btn btn-primary btn-sm">
                            <i class="fa fa-info-circle"></i> Button Label
                        </button>
                        <pre><code class="language-markup">btn btn-primary btn-sm</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Primary Rounded</h4>
                        <button class="btn btn-primary btn-rounded">Button Label</button>
                        <pre><code class="language-markup">btn btn-primary btn-rounded</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Secondary</h4>
                        <button class="btn btn-secondary">Button Label</button>
                        <pre><code class="language-markup">btn btn-secondary</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Secondary Outline</h4>
                        <button class="btn btn-secondary-outline">Button Label</button>
                        <pre><code class="language-markup">btn btn-secondary-outline</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Success</h4>
                        <button class="btn btn-success">Button Label</button>
                        <pre><code class="language-markup">btn btn-success</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Success Outline</h4>
                        <button class="btn btn-success-outline">Button Label</button>
                        <pre><code class="language-markup">btn btn-success-outline</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Danger</h4>
                        <button class="btn btn-danger">Button Label</button>
                        <pre><code class="language-markup">btn btn-danger</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Danger Outline</h4>
                        <button class="btn btn-danger-outline">Button Label</button>
                        <pre><code class="language-markup">btn btn-danger-outline</code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Disabled</h4>
                        <button class="btn btn-primary disabled">Button Label</button>
                        <pre><code class="language-markup">btn btn-primary disabled</code></pre>
                    </div>
                </div>

                <div class="component span-2">
                    <div class="component__wrapper">
                        <h4 class="component__title">Underline Link</h4>
                        <a class="btn-underline">Button Label</a>
                        <pre
                            class="inline"><code class="language-markup">&lt;a class="btn-underline">Button Label&lt;/a></code></pre>
                    </div>
                </div>

                <div class="component">
                    <div class="component__wrapper">
                        <h4 class="component__title">Muted</h4>
                        <button class="btn btn-muted">Button Label</button>
                        <pre><code class="language-markup">btn btn-muted</code></pre>
                    </div>
                </div>
            </div>

            <div class="guidance copy copy-3">
                <p><strong>GUIDANCE</strong></p>
                <ul>
                    <li>
                        Use <strong>.btn-primary</strong> for Save buttons; use
                        <strong>.btn-primary-outline</strong> for cancel buttons. (See example below
                        in Forms section).
                    </li>
                    <li>
                        <strong>.btn-primary</strong> should be used for the primary function of the
                        screen and workflow step navigation. Primary is for the main objective.
                    </li>
                    <li>
                        Use secondary and outline variations when there are sub-functions or actions
                        on the page that will confuse users whether they are connected to the
                        primary function.
                    </li>
                    <li>
                        Use <strong>.btn-rounded</strong> in .page-header when needing to put a
                        button adjacent to the .page-title
                    </li>
                    <li>
                        Use sm, md, and lg when you need scaling differential for the size of your
                        UI. More important functions can be lg and md; less meaningful can be small.
                    </li>
                </ul>
            </div>
        </section>

        <section class="style-section" id="forms">
            <div class="section-header mb-4">
                <h3 class="module-title underline">Forms</h3>
            </div>

            <div class="section-body style-grid forms-grid">
                <div class="component">
                    <div class="component__wrapper">
                        <div class="code">
                            <h4 class="component__title">Basic Form</h4>

                            <pre
                                class="inline mb-3"><code class="language-markup">&lt;form class="form grid-12">
    &lt;div class="field">
        &lt;label for="field-name" class="field-label required">Text Label&lt;/label>
        &lt;input placeholder="Placeholder text" id="field-name" type="text" required />
    &lt;/div>

    &lt;div class="field">
        &lt;label for="field-name" class="field-label required">Email Label&lt;/label>
        &lt;input placeholder="Email" id="field-name" type="email" required />
    &lt;/div>

    &lt;div class="field">
        &lt;label for="field-name" class="field-label required">Select Label&lt;/label>
        &lt;select id="field-name" required>
            &lt;option value="option-1">Option 1&lt;/option>
            &lt;option value="option-2">Option 2&lt;/option>
            &lt;option value="option-3">Option 3&lt;/option>
        &lt;/select>
    &lt;/div>

    &lt;div class="field g-col-6">
        &lt;label for="field-name" class="field-label required">Phone Label&lt;/label>
        &lt;input placeholder="(206) 555-5555" id="field-name" type="tel" required />
    &lt;/div>

    &lt;div class="field g-col-6">
        &lt;label for="field-name" class="field-label required">Date Label&lt;/label>
        &lt;input id="field-name" type="date" required />
    &lt;/div>

    &lt;div class="field">
        &lt;label class="field-label">Checkboxes Label&lt;/label>

        &lt;label class="checkbox-label">
            &lt;input type="checkbox" class="form-control" name="checkbox-name" checked />
            Checkbox 1
        &lt;/label>

        &lt;/label class="checkbox-label">
            &lt;input type="checkbox" class="form-control" name="checkbox-name" />
            Checkbox 2
        &lt;/label>

        &lt;/label class="checkbox-label">
            &lt;input type="checkbox" class="form-control" name="checkbox-name" />
            Checkbox 3
        &lt;/label>
    &lt;/div>

    &lt;div class="field">
        &lt;label for="field-name" class="field-label">Textarea Label&lt;/label>
        &lt;textarea id="field-name">&lt;/textarea>
    &lt;/div>

    &lt;div class="form__actions flex-end">
        &lt;button class="btn btn-primary">Save&lt;/button>
        &lt;button class="btn btn-primary-outline">Cancel&lt;/button>
    &lt;/div>
&lt;/form></code></pre>
                        </div>

                        <div class="example">
                            <form class="form grid-12">
                                <div class="field">
                                    <label for="field-name" class="field-label required"
                                        >Text Label</label
                                    >
                                    <input
                                        placeholder="Placeholder text"
                                        id="field-name"
                                        type="text"
                                        required />
                                </div>

                                <div class="field">
                                    <label for="field-name" class="field-label required"
                                        >Email Label</label
                                    >
                                    <input
                                        placeholder="Email"
                                        id="field-name"
                                        type="email"
                                        required />
                                </div>

                                <div class="field">
                                    <label for="field-name" class="field-label required"
                                        >Select Label</label
                                    >
                                    <select id="field-name" required>
                                        <option value="option-1">Option 1</option>
                                        <option value="option-2">Option 2</option>
                                        <option value="option-3">Option 3</option>
                                    </select>
                                </div>

                                <div class="field g-col-6">
                                    <label for="field-name" class="field-label required"
                                        >Phone Label</label
                                    >
                                    <input
                                        placeholder="(206) 555-5555"
                                        id="field-name"
                                        type="tel"
                                        required />
                                </div>

                                <div class="field g-col-6">
                                    <label for="field-name" class="field-label required"
                                        >Date Label</label
                                    >
                                    <input id="field-name" type="date" required />
                                </div>

                                <div class="field">
                                    <label class="field-label">Checkboxes Label</label>

                                    <label class="checkbox-label">
                                        <input
                                            type="checkbox"
                                            class="form-control"
                                            name="checkbox-name"
                                            checked />
                                        Checkbox 1
                                    </label>

                                    <label class="checkbox-label">
                                        <input
                                            type="checkbox"
                                            class="form-control"
                                            name="checkbox-name" />
                                        Checkbox 2
                                    </label>

                                    <label class="checkbox-label">
                                        <input
                                            type="checkbox"
                                            class="form-control"
                                            name="checkbox-name" />
                                        Checkbox 3
                                    </label>
                                </div>

                                <div class="field">
                                    <label for="field-name" class="field-label"
                                        >Textarea Label</label
                                    >
                                    <textarea id="field-name"></textarea>
                                </div>

                                <div class="form__actions flex-end">
                                    <button class="btn btn-primary">Save</button>
                                    <button class="btn btn-primary-outline">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="guidance inset copy copy-3">
                    <p><strong>GUIDANCE</strong></p>
                    <ul>
                        <li>Spacing between fields is dictated by pairing .form with .grid-XX</li>
                        <li>Labels and inputs should have matching for/id attributes</li>
                        <li>
                            Use the required class on a field's label for the visual cue of
                            required; use the required attribute on the input.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</div>
