import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';

@Component({
  selector: 'water-resource-inventory-area-title',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './water-resource-inventory-area-title.component.html',
  styleUrls: ['./water-resource-inventory-area-title.component.scss'],
})
export class WaterResourceInventoryAreaTitleComponent {
  @Input() waterResourceInventoryAreaName: string;
  @Input() waterResourceInventoryAreaID: string;

  @Input() nameLength: number = null;
  @Input() small: boolean = false;

  constructor() { }

}
