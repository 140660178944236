import { Component, Input } from '@angular/core';
import { default as vegaEmbed, VisualizationSpec } from 'vega-embed';
import { LoadingDirective } from '../../../directives/loading.directive';
import { ChartData } from '../ChartData';

@Component({
  selector: 'land-cover-bar-chart',
  standalone: true,
  imports: [LoadingDirective],
  templateUrl: './land-cover-bar-chart.component.html',
  styleUrl: './land-cover-bar-chart.component.scss'
})
export class LandCoverBarChartComponent {
  @Input() chartData: ChartData[];
  @Input() chartHeight: number = 300;
  @Input() isLoading: boolean = false;

  public chartID: string = 'landCoverBarChart';

  ngOnChanges() {
    const vegaSpec = {
      $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
      width: 'container',
      height: this.chartHeight,
      autosize: {
        type: 'fit-x',
        resize: true
      },
      config: {
        axisY: {
          labelFont: 'Arial',
          labelFontSize: 16,
          labelFontWeight: 'bold',
          titleFontSize: 30,
          titlePadding: 20
        },
        axisX: {
          labelFont: 'Arial',
          labelFontWeight: 'bold',
          titleAnchor: 'start',
          titleBaseline: 'line-bottom',
          titleAlign: 'right',
          titlePadding: 0,
          titleX: -10,
          titleOpacity: 0.5,
          labelOpacity: 0.5
        },
        legend: {
          orient: 'bottom'
        }
      }, data: {
        name: 'table',
        values: this.chartData,
      },
      transform: [
        {
          joinaggregate: [{ op: 'sum', field: 'YValue', as: 'Total' }],
          groupby: ['XValue']
        },
        {
          joinaggregate: [{ op: 'sum', field: 'HoverValue', as: 'TotalPercentage' }],
          groupby: ['XValue']
        }
      ],
      encoding: {
        y: { field: 'XValue', title: '', sort: { field: 'SortOrder' } },
        x: { field: 'YValue', title: 'Total Acres', type: 'quantitative', axis: { gridDash: { value: [3, 4] } } },
        color: {
          sort: 'ascending',
          field: 'Group',
          title: '',
          type: 'nominal',
          scale: {
            domain: ['Riparian Management Zone', 'Extent of Observed Water', 'Floodplain'],
            range: ['#CDF57A', '#9EBBD7', '#9ED7C2']
          }
        },
        order: { field: 'SortOrder' },
        tooltip: [
          { Field: 'XValue', Title: 'Land Cover', Type: 'ordinal' },
          { Field: 'Group', Title: 'Zone', Type: 'ordinal' },
          { Field: 'YValue', Title: 'Acres', Type: 'quantitative', Format: ',.1f' },
          { Field: 'HoverValue', Title: '% Acres', Type: 'quantitative', Format: '.1%' }
        ].map(x => { return { field: x.Field, title: x.Title, type: x.Type, format: x.Format } })
      },
      layer: [
        {
          mark: 'bar'
        },
        {
          mark: { type: 'text', xOffset: 55, fontSize: 14 },
          encoding: {
            text: { value: { expr: 'format(datum.TotalPercentage, ".1%")' }, type: 'quantitative' },
            x: { field: 'Total' },
            color: { value: 'gray' }
          }
        }
      ]
    } as VisualizationSpec;

    vegaEmbed(`#${this.chartID}`, vegaSpec, { renderer: 'svg' }).then(res => { });

  }
}

